import React, { useState, useEffect } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import logo from "../../../images/corona-island/logo-coronaisland.svg"
import styles from "./form.module.scss"
import provinciasYLocalidades from "../../Winter2022/proviciasylocalidades.json"
import { formatDate, validateEmail } from "../../../utils/formFunctions"
import "../../../styles/corona-island.scss"

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Cookies from "js-cookie"
import loadgif from "../../../images/loading.gif"

const theme = createMuiTheme({
  palette: {
    primary: { main: "#0c2340" },
    secondary: { main: "#11cb5f" },
  },
})

const Form = ({ QR }) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState("")
  const [input, setInputs] = useState({
    name: "",
    birthday: "",
    dni: "",
    email: "",
    phone: "",
    province: "",
    location: "",
    question: "",
  })
  const [localidades, setLocalidades] = useState([])
  const [validateError, setValidateError] = useState("")
  const getLocalUser = email =>
    localStorage && JSON.parse(localStorage.getItem(email))
  const setLocalUser = (email, data) =>
    localStorage && localStorage.setItem(email, JSON.stringify(data))

  useEffect(() => {
    let retrievedObject = localStorage.getItem("registrado")
    let registeredUser = JSON.parse(retrievedObject)

    // let retrievedObjectQr = localStorage.getItem("registradoqr")
    // let registeredUserQr = JSON.parse(retrievedObjectQr)

    if (window.location.pathname === "/islacorona/form") {
      if (registeredUser?.registrado) {
        window.location.href = `/islacorona/registered`
      }
    }
  }, [])

  const apiUrl = "https://wacoronabe-prd.azurewebsites.net/api"
  // const apiUrl = "http://localhost:8080/api"

  const MessageValidate = () => {
    switch (validateError) {
      case "email":
        return <h2 className={styles.error}>EL EMAIL ES INVÁLIDO</h2>
      case "empty":
        return <h2 className={styles.error}>DEBE COMPLETAR TODOS LOS CAMPOS</h2>
      case "terms":
        return (
          <h2 className={styles.error}>
            DEBE ACEPTAR LOS TÉRMINOS Y CONDICIONES
          </h2>
        )
      case "success":
        return null
      default:
        return null
    }
  }

  const handleClick = () => {
    if (
      input.name === "" ||
      input.birthday === "" ||
      input.dni === "" ||
      input.email === "" ||
      input.phone === "" ||
      input.province === "" ||
      input.location === "" ||
      input.question === "" ||
      value === ""
    ) {
      setValidateError("empty")
    } else if (validateEmail(input.email)) {
      const LocalData = getLocalUser(input.email)
      setValidateError("")
      let td = Cookies.get("_td") || ""
      let obj = {
        name: input.name,
        birthday: input.birthday,
        dni: input.dni,
        email: input.email,
        phone: input.phone,
        province: input.province,
        location: input.location,
        question: input.question,
        td,
        QR,
      }
      setLoading(true)
      if (QR) {
        try {
          fetch(`${apiUrl}/coronaisland/qrusers`, {
            method: "POST",
            body: JSON.stringify(obj),
            // credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }).then(response => {
            if (response.ok) {
              localStorage.setItem(
                "registradoqr",
                JSON.stringify({ registradoqr: true })
              )
              window.location.href = `/islacoronaregistro/success`
              setLoading(false)
            } else {
              window.location.href = `/islacoronaregistro/registered`
              setLoading(false)
            }
          })
        } catch (error) {
          console.log(error)
          window.location.href = `/islacoronaregistro/error`
          setLoading(false)
        }
      } else {
        try {
          fetch(`${apiUrl}/coronaisland`, {
            method: "POST",
            body: JSON.stringify(obj),
            // credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }).then(response => {
            if (response.ok) {
              localStorage.setItem(
                "registrado",
                JSON.stringify({ registrado: true })
              )
              window.location.href = `/islacorona/success`
              setLoading(false)
            } else {
              window.location.href = `/islacorona/registered`
              setLoading(false)
            }
          })
        } catch (error) {
          console.log(error)
          window.location.href = `/islacorona/error`
          setLoading(false)
        }
      }
    } else {
      setValidateError("email")
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (name === "birthday") {
      const onlyNums = value.replace(/[^0-9]/g, "")
      const date = formatDate(onlyNums)
      return setInputs(inputs => ({ ...inputs, [name]: date }))
    }
    if (name === "province") setLocalidades(provinciasYLocalidades[value].sort())
    setInputs(inputs => ({ ...inputs, [name]: value }))
    setValidateError("")
  }

  const handleChangeRadioButton = event => {
    setValue(event.target.value)
  }

  const answers = [
    "Cuando me quiero relajar en mi casa",
    "Cuando me quiero relajar al aire libre",
    "Cuando me voy de vacaciones",
    "En la playa",
    "Los fines de semana",
    "En verano",
    "En invierno",
    "Todo el año",
    "Con amigxs en una casa",
    "En una fiesta",
    "Mientras trabajo",
    "No tomo cerveza",
  ]

  const provincias = Object.keys(provinciasYLocalidades).sort()

  return (
    <section className={styles.container} id="form">
      <div className={styles.formContainer}>
        <img src={logo} alt="logo" />
        <h2>
          INGRESÁ TUS DATOS <br />
          PERSONALES PARA PARTICIPAR
        </h2>
        <MuiThemeProvider theme={theme}>
          <form noValidate autoComplete="off">
            <TextField
              id="outlined-basic"
              label="Nombre y Apellido"
              variant="outlined"
              className={styles.input}
              onChange={handleChange}
              required={true}
              name="name"
            />
            <TextField
              id="outlined-basic"
              label="Fecha de Nacimiento"
              placeholder="DD/MM/AAAA"
              variant="outlined"
              className={styles.input}
              onChange={handleChange}
              name="birthday"
              value={input.birthday}
            />
            <TextField
              id="outlined-basic6"
              label="DNI"
              type="number"
              variant="outlined"
              required={true}
              className={styles.input}
              onChange={handleChange}
              name="dni"
            />

            <TextField
              id="outlined-basic3"
              label="Email"
              required={true}
              variant="outlined"
              className={styles.input}
              onChange={handleChange}
              name="email"
            />

            <TextField
              id="outlined-basic6"
              label="Teléfono"
              variant="outlined"
              required={true}
              className={styles.input}
              onChange={handleChange}
              name="phone"
              type="number"
            />
            <div>
              <TextField
                id="outlined-select-currency"
                select
                name="province"
                label="Provincia"
                required={true}
                value={input.province}
                onChange={handleChange}
                variant="outlined"
                className={styles.select}
              >
                {provincias.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <TextField
                id="outlined-select-currency"
                select
                name="location"
                label="Localidad"
                required={true}
                value={input.currency}
                onChange={handleChange}
                variant="outlined"
                className={styles.select}
                disabled={!localidades.length}
              >
                {localidades.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <TextField
              id="outlined-select-currency"
              select
              name="question"
              label="¿En qué ocasiones acostumbras consumir cerveza?"
              value={input.currency}
              onChange={handleChange}
              variant="outlined"
              required={true}
              className={styles.select}
            >
              {answers.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <RadioGroup
              aria-label="gender"
              value={value}
              className={styles.inputradio}
              onChange={handleChangeRadioButton}
              name="termsAndConditions"
            >
              <FormControlLabel
                value="female"
                className={styles.radio}
                control={
                  <Radio
                    value="accept"
                    name="radio"
                    inputProps={{ "aria-label": "D" }}
                  />
                }
              />
              <span className={styles.textbases}>
                He leído y acepto{" "}
                <AniLink
                  to="/islacorona/byc"
                  cover
                  direction="left"
                  duration={0.5}
                  bg="#ffc311"
                >
                  bases y condiciones,
                </AniLink>{" "}
                <AniLink
                  to="/islacorona/pdp"
                  cover
                  direction="left"
                  duration={0.5}
                  bg="#ffc311"
                >
                  políticas de privacidad
                </AniLink>{" "}
                y activaciones de marketing.
              </span>
            </RadioGroup>
            <MessageValidate />
            <div className="d-flex justify-content-center">
              {!loading ? (
                <Button
                  onClick={handleClick}
                  variant="contained"
                  color="primary"
                  className={styles.buttonvalidar}
                >
                  PARTICIPAR
                </Button>
              ) : (
                <img
                  src={loadgif}
                  style={{ width: "45px", margin: 0, padding: 0 }}
                />
              )}
            </div>
          </form>
        </MuiThemeProvider>
      </div>
    </section>
  )
}

export default Form
